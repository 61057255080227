<template>
  <Layout>
    <div v-if="isBusy" style="text-align: center">
      <strong>Loading...</strong>
    </div>
    <div v-else>
      <div class="row" v-if="display_ads">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 mb-3">
                  <strong>Display Ads Info</strong>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-4">Name:</div>
                <div class="col-8">{{ display_ads.name }}</div>
              </div>
              <div class="row mb-3">
                <div class="col-4">Start Date:</div>
                <div class="col-8">
                  {{ moment(display_ads.start_date).format("LLL") }}
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-4">End Date:</div>
                <div class="col-8">
                  {{ moment(display_ads.finish_date).format("LLL") }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">Status:</div>
                <div class="col-8">
                  <b-badge v-if="display_ads.status === 1">Active</b-badge>
                  <b-badge v-else>InActive</b-badge>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Assigned Devices Card -->
        <div class="col-12 mt-3">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <strong>Assigned Devices</strong>
                </div>
              </div>
              <table class="table mt-3">
                <thead>
                  <tr>
                    <th scope="col">Device</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="device in display_ads.assigned_devices"
                    :key="device"
                  >
                    <td>{{ device }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- Campaign Files Card -->
        <div class="col-12 mt-3">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <strong>Campaign Files</strong>
                </div>
              </div>
              <table class="table mt-3">
                <thead>
                  <tr>
                    <th scope="col">Preview</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="file in display_ads.campaign_files"
                    :key="file._id"
                  >
                    <td>
                      <div v-if="isImage(file.url)">
                        <img
                          :src="file.url"
                          alt="file preview"
                          style="
                            max-width: 100px;
                            max-height: 100px;
                            cursor: pointer;
                          "
                          @click="showPreview(file.url, 'image')"
                        />
                      </div>
                      <div v-else-if="isVideo(file.url)">
                        <div
                          style="cursor: pointer; color: blue"
                          @click="showPreview(file.url, 'video')"
                        >
                          Show video
                        </div>
                      </div>
                      <div v-else>No preview available</div>
                    </td>
                    <td>
                      <button
                        class="action-btn"
                        @click="downloadFile(file.url)"
                      >
                        <i class="fas fa-download"></i>
                      </button>
                      <button
                        class="action-btn"
                        @click="confirmDeleteFile(file._id)"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Confirmation Modal -->
    <b-modal
      id="delete-confirmation-modal"
      title="Confirm Deletion"
      @ok="deleteFileConfirmed"
    >
      <p class="my-4">Are you sure you want to delete this file?</p>
    </b-modal>

    <!-- File Preview Modal -->
    <b-modal id="file-preview-modal" size="lg" title="File Preview" hide-footer>
      <div v-if="previewType === 'image'">
        <img :src="previewUrl" alt="file preview" style="width: 100%" />
      </div>
      <div v-else-if="previewType === 'video'">
        <video :src="previewUrl" controls style="width: 100%"></video>
      </div>
    </b-modal>
  </Layout>
</template>



<script>
import Layout from "../../layouts/main";
import { display_ads } from "@/config/api/display_ads";

export default {
  props: ["id"],
  components: {
    Layout,
  },
  data() {
    return {
      isBusy: false,
      display_ads: undefined,
      fileIdToDelete: null,
      previewUrl: "",
      previewType: "",
    };
  },
  mounted() {
    this.loadDevices();
  },
  methods: {
    handleFormSubmitted() {
      this.loadDevices();
    },
    confirmDeleteFile(fileId) {
      this.fileIdToDelete = fileId;
      this.$bvModal.show("delete-confirmation-modal");
    },
    isImage(url) {
      return /\.(jpg|jpeg|png|gif)$/i.test(url);
    },
    isVideo(url) {
      return /\.(mp4|webm|ogg)$/i.test(url);
    },
    downloadFile(url) {
      const link = document.createElement("a");
      link.href = url;
      link.download = url.substring(url.lastIndexOf("/") + 1);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    showPreview(url, type) {
      this.previewUrl = url;
      this.previewType = type;
      this.$bvModal.show("file-preview-modal");
    },
    deleteFileConfirmed() {
      this.deleteFile(this.fileIdToDelete);
      this.fileIdToDelete = null;
    },
    deleteFile(fileId) {
      const payload = {
        ad_id: this.display_ads._id,
        file_id: fileId,
      };
      const api = display_ads.removeFile;
      api.data = payload;
      this.creating = true;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("File deleted successfully!", {
            title: "File Deletion",
            variant: "success",
            solid: true,
          });
          this.loadDevices(); // Reload the devices to reflect the deletion
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Failed to delete file: ${err.response.data.error}`,
            {
              title: "File Deletion",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.creating = false;
        });
    },
    loadDevices() {
      this.isBusy = true;
      const api = display_ads.getById;
      api.id = this.id;
      this.generateAPI(api)
        .then((res) => {
          this.display_ads = res.data.data;
          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err);
          this.isBusy = false;
        });
    },
  },
};
</script>



<style scoped>
.vehicleinfo {
  cursor: pointer;
}
.file-item {
  margin-bottom: 10px;
}
</style>

